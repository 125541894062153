import { default as datasettyP3eym0IlMeta } from "/Users/anasr/Repos/web/blanc-web/pages/dataset.vue?macro=true";
import { default as indexP7y42fdhCjMeta } from "/Users/anasr/Repos/web/blanc-web/pages/index.vue?macro=true";
import { default as jobsX6TlmnYAp9Meta } from "/Users/anasr/Repos/web/blanc-web/pages/jobs.vue?macro=true";
export default [
  {
    name: datasettyP3eym0IlMeta?.name ?? "dataset",
    path: datasettyP3eym0IlMeta?.path ?? "/dataset",
    meta: datasettyP3eym0IlMeta || {},
    alias: datasettyP3eym0IlMeta?.alias || [],
    redirect: datasettyP3eym0IlMeta?.redirect,
    component: () => import("/Users/anasr/Repos/web/blanc-web/pages/dataset.vue").then(m => m.default || m)
  },
  {
    name: indexP7y42fdhCjMeta?.name ?? "index",
    path: indexP7y42fdhCjMeta?.path ?? "/",
    meta: indexP7y42fdhCjMeta || {},
    alias: indexP7y42fdhCjMeta?.alias || [],
    redirect: indexP7y42fdhCjMeta?.redirect,
    component: () => import("/Users/anasr/Repos/web/blanc-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: jobsX6TlmnYAp9Meta?.name ?? "jobs",
    path: jobsX6TlmnYAp9Meta?.path ?? "/jobs",
    meta: jobsX6TlmnYAp9Meta || {},
    alias: jobsX6TlmnYAp9Meta?.alias || [],
    redirect: jobsX6TlmnYAp9Meta?.redirect,
    component: () => import("/Users/anasr/Repos/web/blanc-web/pages/jobs.vue").then(m => m.default || m)
  }
]